import styled from "styled-components";

export const HeaderSmallTitle = styled.div`
  display: block;
  position: relative;
  margin-bottom: 5rem;
`;

export const HeaderDescContainer = styled.div`
  display: block;
  position: relative;
`;
