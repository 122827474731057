import React from 'react';

import { AllCapsSubTypo, LargeHeaderTypo, LargeHeaderTypoP } from '../commonStyles/typoTheme';
import { SectionHeaderMargin } from '../commonStyles/SectionsStyle';
import { HeaderDescContainer, HeaderSmallTitle } from '../commonStyles/HeaderTextStyle';


class SectionHeader extends React.PureComponent {
  render() {
    return (
      <SectionHeaderMargin>
        <HeaderDescContainer>
          <HeaderSmallTitle>
            <AllCapsSubTypo>
              HELLO, WE ARE BORDER3PX
            </AllCapsSubTypo>
          </HeaderSmallTitle>
          <LargeHeaderTypo>
            We create beautiful <LargeHeaderTypoP>experiences</LargeHeaderTypoP>, eye-catching websites and functional products.
          </LargeHeaderTypo>
        </HeaderDescContainer>
      </SectionHeaderMargin>
    );
  }
}

export default SectionHeader;
