import React from 'react';

import SectionHeader from '../components/SectionHeader';
import SectionProject from '../components/SectionProject';

import malvaBrand from '../images/malva.jpg'
import hioiBrand from '../images/hioi.jpg'
import sequelBrand from '../images/sequel.jpg'
import acaraBrand from '../images/acara.jpg'
import wogBrand from '../images/wog.jpg'
import stillLifesBrand from '../images/stilllifes.jpg'

class IndexComponent extends React.PureComponent {

  render() {
      return (
        <>
          <SectionHeader />
          <SectionProject
            isFeaturedProject={true}
            bgImg={'url(' + hioiBrand + ')'}
            isLeft={true}
            projectTitle={'Hioi - project management'}
            projectSubtitle={'User Interface, User Experience, Visual Identity'}
            projectNumber={'01'}
            url={'/hioi'}
          />
          <SectionProject
            bgImg={'url(' + acaraBrand + ')'}
            isLeft={false}
            projectTitle={'Acara - event marketing platform'}
            projectSubtitle={'User Interface, User Experience, Visual Identity'}
            projectNumber={'02'}
            url={'/acara'}
          />
          <SectionProject
            bgImg={'url(' + sequelBrand + ')'}
            isLeft={true}
            projectTitle={'Team Sequel - in person events '}
            projectSubtitle={'Visual Identity, Website'}
            projectNumber={'03'}
            url={'/teamsequel'}
          />
          <SectionProject
            bgImg={'url(' + wogBrand + ')'}
            isLeft={false}
            projectTitle={'World of Gemstones - minerals shop'}
            projectSubtitle={'Visual Identity'}
            projectNumber={'04'}
            url={'/wog'}
          />
          <SectionProject
            bgImg={'url(' + stillLifesBrand + ')'}
            isLeft={true}
            projectTitle={'3d still lifes'}
            projectSubtitle={'3d models'}
            projectNumber={'05'}
            url={'/stilllifes'}
          />
          <SectionProject
            bgImg={'url(' + malvaBrand + ')'}
            isLeft={false}
            projectTitle={'Malva Gems - independent jewelry'}
            projectSubtitle={'Visual Identity'}
            projectNumber={'06'}
            url={'/malva'}
          />
        </>
      );
  }
}

export default IndexComponent;
